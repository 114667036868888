import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/waydev-alternative-meta.png'
import Layout from '../../components/Layout'
import { DarkLogoBlock } from '../../components/blocks/DarkLogoBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import Box from '../../components/primitives/Box'
import Row from '../../components/primitives/Row'
import Stack from '../../components/primitives/Stack'
import CTABlock from '../../components/blocks/CTABlock'

export const query = graphql`
  {
    backgroundImage: file(relativePath: { eq: "images/backgrounds/sky.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/business-outcomes.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 690)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/developer-productivity.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 690)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/survey-narrow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight3: file(relativePath: { eq: "images/mosaic/blue-surveys.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/blue-investment.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight4: file(
      relativePath: { eq: "images/mosaic/navy-notifications.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`
const WaydevPage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout
      title="Waydev vs. Swarmia"
      variant="dark"
      isNew
      description="Explore the main differences between Waydev and Swarmia to find out which tool is a better fit for your engineering organization."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>Waydev vs. Swarmia</>}
        content="Waydev offers a high-level overview of engineering metrics. If you‘re looking for the insights and automations to drive meaningful change in your engineering organization, Swarmia is a better choice."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock heading="Swarmia guides you on a path of continuous improvement across three key areas" />
      <MosaicBlock
        title="Drive planned business outcomes"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                CTOs, VPEs, and engineering directors use Swarmia to make
                decisions that move the business in the right direction.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Investment balance
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Initiatives
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Software capitalization
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight)!}
      />
      <MosaicBlock
        title="Systematically improve developer productivity"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Engineering directors and managers use Swarmia to identify and
                eliminate software delivery bottlenecks.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Engineering metrics
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Work log
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Developer overview
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="left"
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Create better developer experience"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Give your software teams the tools they need to improve the
                experience of shipping software.
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Working agreements
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Slack notifications
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                CI insights
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Developer experience surveys
              </Box>
            </Row>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight2)!}
      />
      <QuoteBlock person="walter" />
      <LeadBlock
        heading="Why leading engineering teams choose Swarmia over Waydev"
        content="Modern organizations need more than passive reports and basic automations. Swarmia gives you granular engineering metrics and drives concrete improvements across all levels of the organization."
      />
      <MosaicBlock
        title="Get a holistic picture of your engineering organization"
        content={
          <>
            Swarmia helps you combine system metrics and developer feedback from
            engineering surveys so you know exactly what to improve and how.
            <br />
            <br />
            The survey results are easy to collect and turn into concrete action
            points. With Waydev, you need to solely rely on quantitative
            reports, missing the deeper context that comes from hearing directly
            from your developers.
          </>
        }
        image={getImage(data.mosaicRight3)!}
        imageAlign="right"
      />
      <MosaicBlock
        title="Understand and balance engineering investments"
        content={
          <>
            Swarmia’s investment balance provides an intuitive view of how your
            organization divides time between different types of work. Waydev’s
            similar tools focus mainly on the high-level views, and are more
            time-consuming to set up and keep up to date.
            <br />
            <br />
            The investment categories in Swarmia are fully configurable,
            allowing you to track any type of work you want. After setting basic
            rules for categorizing issues and PRs, Swarmia automatically splits
            them, making it easier to maintain accurate investment tracking.
            <br />
            <br />
          </>
        }
        image={getImage(data.mosaicLeft2)!}
        imageAlign="left"
      />
      <MosaicBlock
        title="Use working agreements to improve as a team"
        content={
          <>
            Both Swarmia and Waydev integrate with Slack to send real-time
            notifications. However, Swarmia also lets you set team-wide working
            agreements to align with key priorities.
            <br />
            <br />
            Whether you want to follow industry best practices or set numeric
            targets, Swarmia has a working agreement for that.
          </>
        }
        image={getImage(data.mosaicRight4)!}
        imageAlign="right"
      />
      <QuoteBlock person="alex4" />
      <CTABlock title="Swarmia is how the best engineering teams get better. Get started today." />
    </Layout>
  )
}

export default WaydevPage
